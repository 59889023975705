let getElementOffset = function(element) {
    return element.getBoundingClientRect();
};

export let setScrollLink = function(query) {
    let links = document.querySelectorAll(query);

    if (links) {
        links.forEach(function(el) {
            el.addEventListener("click", function(e) {
                let hash = el.getAttribute("href").replace(/#/g, "");
                let targetElement = document.getElementById(hash);

                if (targetElement) {
                    e.preventDefault();
                    scrollTo(targetElement);
                }
            });
        });
    }
};

export let scrollTo = function(targetElement) {
    var screenSize = window.innerWidth;
    let scrollOffset;
    if (screenSize <= 768) {
        //account for nav on mobile
        scrollOffset = 50;
    } else {
        scrollOffset = 10;
    }
    window.scrollTo({
        top: getElementOffset(targetElement).top - scrollOffset,
        left: 0,
        behavior: "smooth"
    });
};
