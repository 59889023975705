import "../../css/pages/sustainability.scss";
import sectionNav from "../modules/sectionNav";
import sectionNavV2 from "../modules/sectionNavV2";

import BasePage from "./base";
import { setScrollLink } from "../modules/scrollTo";

class SustainabilityPage extends BasePage {
    constructor(...opts) {
        super(...opts);
    }

    onReady() {
        super.onReady();
        sectionNav.init();
        sectionNavV2.init();
        setScrollLink(".heroLink--anchor");
    }
}

new SustainabilityPage();
